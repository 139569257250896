import React from 'react'

// This document is to serve as global constants for the site. When making edits, this should be kept in mind so that future ecommerce sites can be easily be cloned and reformatted within this file alone.

// Strings

//color selector: https://coolors.co/generate
let logoText = 'Shop.thewebdev.net'

//Product Carrousel,
let primaryColor = '#4bb781'
//Navbar,
let secondaryColor = '#3d5aaf'

let primaryTextColor = 'white'

let defaultTitle = 'Get a website today'

let defaultShippingCost = 0

let productCaption = { color: '#EADEDA', textShadow: '1px 3px 5px black' }

// Components
const CopyRight = () => {
  const year = new Date().getFullYear()

  return (
    <p>
      &copy; {year} <a href='//www.thewebdev.net'>LemaTech LLC</a>
    </p>
  )
}

export {
  logoText,
  CopyRight,
  primaryColor,
  secondaryColor,
  primaryTextColor,
  defaultShippingCost,
  defaultTitle,
  productCaption,
}
